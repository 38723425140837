
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class WarningDetail extends Vue {
  private msgInfo= {
    title: '',
    sendTime: '',
    msg: ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.messageWarning.deteil, {
      id: this.$route.params.id
    }).then((res) => {
      this.msgInfo = {
        title: res.title,
        sendTime: res.sendTime,
        msg: res.msg
      }
      this.$store.dispatch('getMessageNum')
    })
  }
}
